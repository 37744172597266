

export const AUTH_TOKEN_COOKIE = 'dglogin';

// Hardcoded phone numbers that can manage admin status of other users
export const SUPER_ADMINS = [
  '+18315551234',
];

export const AUTH_EXCLUDED_NUMBERS = [
  '18315550000',
  '18315551234',
  '18315555555',
];

export const SUPPORT_EMAIL = 'support@dreamgifts.co';

export const appUrl = process.env.NODE_ENV === 'production' ? 'https://www.dreamgifts.co' : 'http://localhost:3000';
