import mixpanel from 'mixpanel-browser';

class AppAnalytics {
  private identifiedAs: string;

  constructor() {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY);
    this.identifiedAs = '';
  }

  identify(uid: string, properties?: Record<string, unknown>) {
    if (this.identifiedAs !== uid) {
      mixpanel.identify(uid);
      this.identifiedAs = uid;
    }

    if (properties) {
      this.setUserProperties(properties);
    }
  }

  setUserProperties(properties: Record<string, unknown>, setOnce?: boolean) {
    if (setOnce) {
      mixpanel.people.set_once(properties);
    } else {
      mixpanel.people.set(properties)
    }
  }

  setContext(properties: Record<string, unknown>, setOnce?: boolean) {
    if (setOnce) {
      mixpanel.register(properties);
    } else {
      mixpanel.register_once(properties);
    }
  }

  track(event: string, properties: Record<string, unknown>) {
    mixpanel.track(event, properties);
  }

}

export default new AppAnalytics();