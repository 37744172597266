import React, { useEffect } from 'react';

import '../styles/tailwind.css';
import '../styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';

import { AnimateSharedLayout } from 'framer-motion';
import Head from 'next/head';
import { ToastContainer } from 'react-toastify';
import { appUrl } from 'lib/constants';

import analytics from 'lib/analytics';
import sourceTracker from 'lib/sourceTracker';

interface AppProps {
  Component: React.ComponentType;
  pageProps: any;
}

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  useEffect(() => {
    sourceTracker.setSource();

    analytics.track('Page View', {
      href: window.location.href,
    });

  }, []);

  return (<AnimateSharedLayout>
    <Head>
      {
        process.env.NODE_ENV === 'development' ?
          (
            <>
              <link rel="icon" type="image/png" href="/favicons/dreamgifts-dev-favicon.png" />
            </>
          ) :
          (
            <>
              <link rel="icon" type="image/x-icon" href="/favicon.ico" />
              <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
              <link rel="manifest" href="/favicons/site.webmanifest" />
              <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#5bbad5" />
              <meta name="msapplication-TileImage" content="/favicons/mstile-150x150.png" />
            </>
          )
      }
      
      <meta name="msapplication-TileColor" content="#8940c1" />
      <meta name="theme-color" content="#ffffff" />
      <meta property="og:image" content="/img-og.jpg" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Dreamgifts" />
      <meta name="twitter:description" content="Let your fans buy you gifts from any brand" />
      <meta name="twitter:image" content={`${appUrl}/img-twitter.jpg`} />
    </Head>
    <Component {...pageProps} />
    <ToastContainer />
  </AnimateSharedLayout>
  );
}

export default App;
