

const storageKey = 'dreamgifts_whence';

class SourceTracker {
  private disabled: boolean;

  constructor() {
    this.disabled = typeof document === 'undefined' || typeof localStorage === 'undefined';
  }

  setSource() {
    if (this.disabled) { return; }

    if (typeof localStorage.getItem(storageKey) !== 'string') {
      try {
        localStorage.setItem(storageKey, document.referrer);
      } catch {
        // unsupported
      }
    }
  }

  get source() {
    if (this.disabled) {
      return '';
    }

    let source = document.referrer;

    try {
      source = localStorage.getItem(storageKey) || document.referrer;
    } catch {
      // unsupported
    }

    return source;
  }
}

export default new SourceTracker();